export const formatTime = function formatTime(dateString) {
  const date = new Date(dateString);
  return new Intl.DateTimeFormat("en-GB", {
    day: "2-digit",
    month: "long",
    year: "numeric",
  }).format(date);
};

export const getCurrentDateFormatted = () => {
  const now = new Date();
  const day = now.getDate();
  const month = now.getMonth();
  const year = now.getFullYear();

  // Define month names
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  // Define day suffixes
  const getDaySuffix = (day) => {
    if (day >= 11 && day <= 13) return "th"; // Special case for 11th, 12th, 13th
    switch (day % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };

  const daySuffix = getDaySuffix(day);
  const monthName = monthNames[month];

  return `${day}${daySuffix} ${monthName} ${year}`;
};

export const getCurrentTimeFormatted = () => {
  const now = new Date();
  let hours = now.getHours();
  const minutes = now.getMinutes();

  // Determine AM or PM
  const period = hours >= 12 ? "PM" : "AM";

  // Convert hours from 24-hour to 12-hour format
  hours = hours % 12 || 12; // `12` for midnight or noon

  // Format minutes with leading zero if necessary
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

  // Format hours and return the formatted time string
  return `${hours}:${formattedMinutes} ${period}`;
};

export const openLoginModal = () => {
  document.getElementById("open-login-modal-btn").click();
};

export const closeLoginModal = () => {
  document.getElementById("close-login-modal-btn").click();
};

export const convertDataURLToFile = (dataURL, fileName, fileType) => {
  const arr = dataURL.split(",");
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], fileName, { type: fileType || mime });
};

export function getFileInfoFromURL(url) {
  // Use URL constructor to parse the URL
  const parsedUrl = new URL(url);

  // Get the file name from the URL pathname
  const fileName = parsedUrl.pathname.split("/").pop();

  // Extract the file type (extension) from the file name
  const fileType = fileName.split(".").pop();

  return {
    fileName: fileName,
    fileType: fileType,
  };
}
