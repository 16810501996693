import axiosFormDataInstance from "./axiosFormDataInstance";
import axiosJSONInstance from "./axiosJSONInstance";

export const fetchStores = async () => {
    try {
        const response = await axiosJSONInstance.get(`/api/v1/stores/`);

    return response.data.data;
  } catch (error) {
    console.error("Error fetching admin stores", error);
    throw error;
  }
};

// create admin store
export const createAdminStore = async (data) => {
  try {
    const response = await axiosFormDataInstance.post(`/api/v1/stores/`, data);

    return response.data.data;
  } catch (error) {
    console.error("Error creating store", error);
    throw error;
  }
};

// delete store
export const deleteStore = async (storeId) => {
  try {
    const response = await axiosJSONInstance.delete(
      `/api/v1/stores/${storeId}/`
    );

    return response.data.data;
  } catch (error) {
    console.error("Error deleting store", error);
    throw error;
  }
};

// fetch store details
export const fetchAdminStoreDetails = async (storeId) => {
  try {
    const response = await axiosJSONInstance.get(
      `/api/v1/stores/${storeId}/`
    );

    return response.data.data;
  } catch (error) {
    console.error("Error fetching store details", error);
    throw error;
  }
};

// update store details
export const updateStoreDetails = async (storeId, data) => {
  try {
    const response = await axiosFormDataInstance.put(
      `/api/v1/stores/${storeId}/`,
      data
    );

    return response.data.data;
  } catch (error) {
    console.error("Error updating store details", error);
    throw error;
  }
};

export const fetchUserInfo = async (userId) => {
  try {
    const response = await axiosJSONInstance.get(
      `/api/v1/auth/staff/profile/${userId}`
    );

    return response.data;
  } catch (error) {
    console.error("Error fetching admin stores", error);
    throw error;
  }
};

export const removeStoreLogo = async (storeId) => {
  try {
    const response = await axiosJSONInstance.delete(
      `/api/v1/stores/${storeId}/logo/remove/`
    );

    return response.data;
  } catch (error) {
    console.error("Error removing store logo", error);
    throw error;
  }
};
