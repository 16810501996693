<template>
    <button type="button" hidden class="btn btn-primary" id="open-login-modal-btn" data-bs-toggle="modal"
        data-bs-target="#exampleModalScrollable2">
        Open Login Modal
    </button>
    <div class="modal fade" id="exampleModalScrollable2" data-bs-backdrop="static" tabindex="-1"
        aria-labelledby="exampleModalScrollable2" data-bs-keyboard="false" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title text-center w-100" id="staticBackdropLabel2">Session Expired - Login To
                        Continue
                    </h4>
                </div>
                <div class="modal-body">
                    <form @submit.prevent="submitForm">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="mb-3">
                                    <label class="form-label">Email Address <span> *</span></label>
                                    <input type="email" v-model="email" name="email" class="form-control" />
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="mb-3">
                                    <label class="form-label">Password <span> *</span></label>
                                    <input type="password" v-model="password" name="password" class="form-control" />
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="modal-footer">
                    <button type="button" data-bs-dismiss="modal" id="close-login-modal-btn" hidden data-bs-target="#exampleModalScrollable2"
                        class="btn btn-danger">Close</button>
                    <button type="button" class="btn btn-primary w-100" @click="submitForm">Continue</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { logIn } from '@/api/login';
import { closeLoginModal } from '@/assets/js/utils';
import { authState, saveAuthState } from '@/state/authState';

export default {
    data() {
        return {
            email: '',
            password: ''
        };
    },
    methods: {
        submitForm() {
            // Perform authentication logic here
            logIn(JSON.stringify({
                email: this.email,
                password: this.password
            })).then(data => {
                Swal.fire({
                    title: "Reauthenticated Successfully",
                    icon: "success",
                    toast: true,
                    position: "top-end",
                    showConfirmButton: false,
                    timer: 1000,
                })

                // update auth state
                authState.accessToken = data.access_token
                authState.refreshToken = data.refresh_token
                authState.user = data.user
                authState.userProfile = data.user_profile

                saveAuthState()

                // reset form
                this.email = '';
                this.password = '';

                // close modal
                closeLoginModal();

            }).catch(error => {
                Swal.fire({
                    title: "Authentication Failed",
                    text: error.response?.data?.message || error.message,
                    icon: "error",
                    text: error?.response?.data?.message || error.message,
                    confirmButtonText: "Try Again",
                })
            })
        }
    }
}
</script>