import axiosJSONInstance from "./axiosJSONInstance";

export const fetchStoreBrands = async (storeId) => {
  try {
    const response = await axiosJSONInstance.get(
      `/api/v1/stores/${storeId}/brands/`
    );

    return response.data.data;
  } catch (error) {
    console.error("Error fetching store brands", error);
    throw error;
  }
};

export const createStoreBrand = async (storeId, data) => {
  try {
    const response = await axiosJSONInstance.post(
      `/api/v1/stores/${storeId}/brands/`,
      data
    );

    return response.data.data;
  } catch (error) {
    console.error("Error creating brand", error);
    throw error;
  }
};
