<template>
  <layout-header></layout-header>
  <layout-sidebar></layout-sidebar>
  <div class="page-wrapper">
    <div class="content">
      <div class="page-header">
        <div class="page-title">
          <h4>Product Details</h4>
          <h6>Full details of a product</h6>
        </div>
        <div class="page-btn d-flex flex-column gap-2">
          <router-link
            :to="`/inventory/product-list`"
            class="btn btn-added btn-secondary"
            ><vue-feather type="arrow-left" class="me-2"> </vue-feather>Back to
            Product List
          </router-link>
          <router-link
            :to="`/inventory/products/${productId}/edit-product`"
            class="btn btn-added"
            ><vue-feather type="edit" class="me-2"></vue-feather>Edit
            Product</router-link
          >
        </div>
      </div>
      <!-- /add -->
      <div class="row">
        <div class="col-lg-8 col-sm-12">
          <div class="card">
            <div class="card-body">
              <!-- <div class="bar-code-view">
                <img src="@/assets/img/barcode/barcode1.png" alt="barcode" />
                <a class="printimg">
                  <img src="@/assets/img/icons/printer.svg" alt="print" />
                </a>
              </div> -->
              <div class="productdetails">
                <ul class="product-bar">
                  <li>
                    <h4>SKU</h4>
                    <h6>{{ product.reference_id }}</h6>
                  </li>
                  <li>
                    <h4>Product Name</h4>
                    <h6>{{ product.product_name }}</h6>
                  </li>
                  <li>
                    <h4>Category</h4>
                    <h6>{{ product?.category?.category_name }}</h6>
                  </li>
                  <li>
                    <h4>Brand</h4>
                    <h6>{{ product?.brand?.name }}</h6>
                  </li>
                  <!-- <li>
                    <h4>Unit</h4>
                    <h6>Piece</h6>
                  </li> -->
                  <li>
                    <h4>Quantity In Stock</h4>
                    <h6>{{ product.quantity_in_stock }}</h6>
                  </li>
                  <li>
                    <h4>Minimum Alert Quantity</h4>
                    <h6>{{ product.minimum_alert_quantity }}</h6>
                  </li>
                  <li>
                    <h4>Cost Price</h4>
                    <h6>{{ product.cost_price }}</h6>
                  </li>
                  <li>
                    <h4>Selling Price</h4>
                    <h6>{{ product.selling_price }}</h6>
                  </li>
                  <!-- <li>
                    <h4>Tax</h4>
                    <h6>0.00 %</h6>
                  </li>
                  <li>
                    <h4>Discount Type</h4>
                    <h6>Percentage</h6>
                  </li> -->
                  <li>
                    <h4>Description</h4>
                    <h6>
                      {{ product.description }}
                    </h6>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-sm-12">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title text-center">Product Images</h5>
              <div class="slider-product-details">
                <div class="owl-carousel owl-theme product-slide">
                  <div class="slider-product">
                    <img
                      :src="
                        product.image ||
                        require('@/assets/img/products/default-product-image.png')
                      "
                      alt="img"
                    />
                  </div>
                  <!-- <div class="slider-product">
                    <img src="@/assets/img/products/product69.jpg" alt="img" />
                    <h4>macbookpro.jpg</h4>
                    <h6>581kb</h6>
                  </div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- /add -->
    </div>
  </div>
</template>

<script>
import { fetchStoreProductDetails } from "@/api/products";
import { selectedStoreState } from "@/state/storeState";

export default {
  name: "ProductDetails",
  data() {
    return {
      product: {},
      productId: null,
    };
  },
  async created() {
    const { productId } = this.$route.params;
    this.productId = productId;
    this.product = await fetchStoreProductDetails(
      selectedStoreState.id,
      productId
    );
  },
  methods: {
    // methods
  },
};
</script>
