import axios from "axios";

// Import the auth state
import { authState } from "@/state/authState";

const axiosJSONInstance = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  timeout: 10000,
  headers: { "Content-Type": "application/json" },
});

// Add a request interceptor
axiosJSONInstance.interceptors.request.use(
  (config) => {
    // Get the access token from the auth state
    const token = authState.accessToken;
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    // Handle the error
    return Promise.reject(error);
  }
);

export default axiosJSONInstance;
