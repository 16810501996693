import axiosJSONInstance from "./axiosJSONInstance";


export const signUp = async data => {
    try {
        const response = await axiosJSONInstance.post(
            "/api/v1/auth/admin/signup/",
            data
        );

        if (response.status >= 200 && response.status < 300) {
            return response.data.data;
        } else {
            throw new Error("Failed to signup");
        }
    } catch (error) {
        console.error("Error submitting signup details", error);
        throw error;
    }
};