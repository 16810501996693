<template>
  <layout-header></layout-header>
  <layout-sidebar></layout-sidebar>

  <div class="page-wrapper cardhead">
    <div class="content container-fluid">
      <!-- Page Header -->
      <layout-breadcrumb :title="title" :text="text" :text1="text1" />
      <!-- /Page Header -->

      <div class="row">
        <!-- Counter -->
        <div class="col-md-4">
          <div class="card">
            <div class="card-body">
              <h5>Clients</h5>
              <count-up
                class="counter"
                ref="counter"
                :startVal="1000"
                :delay="3"
                :endVal="3000"
                :duration="5"
                separator=","
                :autoplay="true"
              />
            </div>
          </div>
        </div>
        <!-- /Counter -->

        <!-- Counter -->
        <div class="col-md-4">
          <div class="card">
            <div class="card-body">
              <h5>Total Sales</h5>
              <count-up
                class="counter"
                ref="counter"
                :startVal="3000"
                :delay="3"
                :endVal="10000"
                :duration="5"
                separator=","
                :autoplay="true"
              />
            </div>
          </div>
        </div>
        <!-- /Counter -->

        <!-- Counter -->
        <div class="col-md-4">
          <div class="card">
            <div class="card-body">
              <h5>Total Projects</h5>
              <count-up
                class="counter"
                ref="counter"
                :startVal="5000"
                :delay="3"
                :endVal="15000"
                :duration="5"
                separator=","
                :autoplay="true"
              />
            </div>
          </div>
        </div>
        <!-- /Counter -->

        <!-- Counter -->
        <div class="col-md-4">
          <div class="card">
            <div class="card-header">
              <h5 class="card-title">Count Down</h5>
            </div>
            <div class="card-body">
              <h6>Time Count from 3</h6>
              <count-up
                ref="counter"
                :startVal="1"
                :endVal="3"
                :duration="50"
                prefix="00 Days 00 : "
                decimalSeparator=" : "
                :decimals="2"
                :autoplay="true"
              />
            </div>
          </div>
        </div>
        <!-- /Counter -->

        <!-- Counter -->
        <div class="col-md-4">
          <div class="card">
            <div class="card-header">
              <h5 class="card-title">Count Up</h5>
            </div>
            <div class="card-body">
              <h6>Time Counting From 0</h6>
              <count-up
                ref="counter"
                :startVal="0"
                :endVal="3"
                :duration="100"
                prefix="00 Days 00 : "
                decimalSeparator=" : "
                :decimals="2"
                :autoplay="true"
              />
            </div>
          </div>
        </div>
        <!-- /Counter -->

        <!-- Counter -->
        <div class="col-md-4">
          <div class="card">
            <div class="card-header">
              <h5 class="card-title">Count Inbetween</h5>
            </div>
            <div class="card-body">
              <h6>Time counting from 30 to 20</h6>
              <count-up
                ref="counter"
                :startVal="30"
                :endVal="20"
                :duration="1000"
                prefix="00 Days 00 : "
                decimalSeparator=" : "
                :decimals="2"
                :autoplay="true"
              />
            </div>
          </div>
        </div>
        <!-- /Counter -->

        <!-- Counter -->
        <div class="col-md-4">
          <div class="card">
            <div class="card-header">
              <h5 class="card-title">Count Callback</h5>
            </div>
            <div class="card-body">
              <h6>Count from 10 to 0 and calls timer end callback</h6>
              <count-up
                ref="counter"
                :startVal="0"
                :endVal="3"
                :duration="100"
                prefix="00 Days 00 : "
                decimalSeparator=" : "
                :decimals="2"
                :autoplay="true"
              />
            </div>
          </div>
        </div>
        <!-- /Counter -->

        <!-- Counter -->
        <div class="col-md-4">
          <div class="card">
            <div class="card-header">
              <h5 class="card-title">Custom Output</h5>
            </div>
            <div class="card-body">
              <h6>Changed output pattern</h6>
              <count-up
                ref="counter"
                :startVal="10"
                :endVal="60"
                :duration="2000"
                prefix="02 Days 23 Hour : "
                decimalSeparator=" : "
                :decimals="2"
                :autoplay="true"
              />
            </div>
          </div>
        </div>
        <!-- /Counter -->
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      title: "Counter",
      text: "Dashboard",
      text1: "Counter",
    };
  },
};
</script>
