import axiosJSONInstance from "./axiosJSONInstance";

export const updateSaleItem = async (saleId, data) => {
  try {
    const response = await axiosJSONInstance.put(
      `/api/v1/stores/sale/${saleId}/items/update/`,
      JSON.stringify(data)
    );

    return response.data.data;
  } catch (error) {
    console.error("Error updating sale item line", error);
    throw error;
  }
};
