import axiosJSONInstance from "./axiosJSONInstance";

export const fetchSaleDetails = async (storeId, saleId) => {
  try {
    const response = await axiosJSONInstance.get(
      `/api/v1/stores/${storeId}/sales/${saleId}`
    );

    return response.data.data;
  } catch (error) {
    console.error("Error fetching sales detail", error);
    throw error;
  }
};

export const createSale = async (storeId, data) => {
  try {
    const response = await axiosJSONInstance.post(
      `/api/v1/stores/${storeId}/sales/`,
      data
    );

    return response.data.data;
  } catch (error) {
    console.error("Error creating sale", error);
    throw error;
  }
};
