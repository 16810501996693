<template>
  <div v-show="showSideBar" class="sidebar" id="sidebar">
    <div class="sidebar-inner slimscroll">
      <PerfectScrollbar
        class="scroll-area"
        :settings="settings"
        @ps-scroll-y="scrollHanle"
      >
        <div id="sidebar-menu" class="sidebar-menu">
          <!-- <user-menu></user-menu> -->
          <vertical-sidebar></vertical-sidebar>
        </div>
      </PerfectScrollbar>
    </div>
  </div>
  <collapsed-sidebar v-show="showSideBar"></collapsed-sidebar>
  <horizontal-sidebar v-show="showSideBar"></horizontal-sidebar>
</template>

<script>
import { authState } from "@/state/authState";
import { PerfectScrollbar } from "vue3-perfect-scrollbar";
import "vue3-perfect-scrollbar/style.css";
export default {
  components: {
    PerfectScrollbar,
  },
  data() {
    return {
      settings: {
        suppressScrollX: true,
      },
      showSideBar: true,
    };
  },
  methods: {
    scrollHanle() {},
  },
  created() {
    if (authState.user.role == "STAFF") {
      this.showSideBar = false;
    }
  }
};
</script>
