<template>
  <layout-header></layout-header>
  <layout-sidebar></layout-sidebar>
  <div class="page-wrapper">
    <div class="content">
      <div class="page-header">
        <div class="add-item d-flex">
          <div class="page-title">
            <h4>New Employee</h4>
            <h6>Create new Employee</h6>
          </div>
        </div>
        <ul class="table-top-head">
          <li>
            <div class="page-btn">
              <router-link to="employees-list" class="btn btn-secondary"
                ><vue-feather type="arrow-left" class="me-2"></vue-feather>Back
                to Employee List</router-link
              >
            </div>
          </li>
          <li>
            <a
              ref="collapseHeader"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Collapse"
              @click="toggleCollapse"
            >
              <i
                data-feather="chevron-up"
                :style="collapsed ? { transform: 'rotate(180deg)' } : {}"
                class="feather-chevron-up"
              ></i>
            </a>
          </li>
        </ul>
      </div>
      <!-- Employee list -->
      <form action="" @submit="saveEmployee">
        <div class="card">
          <div class="card-body">
            <div class="new-employee-field">
              <div class="card-title-head">
                <h6>
                  <span
                    ><vue-feather type="info" class="info"></vue-feather></span
                  >Employee Information
                </h6>
              </div>
              <div class="profile-pic-upload">
                <div class="profile-pic">
                  <span
                    ><vue-feather
                      type="plus-circle"
                      class="plus-down-add"
                    ></vue-feather>
                    Profile Photo</span
                  >
                </div>
                <div class="input-blocks mb-0">
                  <div class="image-upload mb-0">
                    <input type="file" />
                    <div class="image-uploads">
                      <h4>Change Image</h4>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-4 col-md-6">
                  <div class="mb-3">
                    <label class="form-label">First Name</label>
                    <input
                      type="text"
                      v-model="first_name"
                      class="form-control"
                    />
                  </div>
                </div>
                <div class="col-lg-4 col-md-6">
                  <div class="mb-3">
                    <label class="form-label">Last Name</label>
                    <input
                      type="text"
                      v-model="last_name"
                      class="form-control"
                    />
                  </div>
                </div>
                <div class="col-lg-4 col-md-6">
                  <div class="mb-3">
                    <label class="form-label">Email</label>
                    <input type="email" v-model="email" class="form-control" />
                  </div>
                </div>
                <div class="col-lg-4 col-md-6">
                  <div class="mb-3">
                    <label class="form-label">Contact Number</label>
                    <input
                      type="text"
                      v-model="contact_number"
                      class="form-control"
                    />
                  </div>
                </div>

                <!-- Gender -->
                <div class="col-lg-4 col-md-6">
                  <div class="mb-3">
                    <label class="form-label">Stores</label>
                    <vue-select
                      :options="storesSelect"
                      @select="updateStoresArray"
                      id="stores"
                      placeholder="Select store"
                    />
                  </div>
                  <!-- Showing the selected stores -->
                  <ul class="stores">
                    <li class="store" v-for="store in stores" :key="store">
                      <span>{{ store }}</span>
                      <vue-feather
                        @click="removeStore(store)"
                        type="x"
                      ></vue-feather>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="pass-info">
                <div class="card-title-head">
                  <h6>
                    <span><i data-feather="info" class="feather-edit"></i></span
                    >Password
                  </h6>
                </div>
                <div class="row">
                  <div class="col-lg-4 col-md-6">
                    <div class="input-blocks mb-md-0 mb-sm-3">
                      <label>Password</label>
                      <div class="pass-group">
                        <input
                          type="password"
                          class="pass-input"
                          @input="checkConfirmPassword"
                          v-model="password"
                          name="password"
                        />
                        <span class="fas toggle-password fa-eye-slash"></span>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-6">
                    <div class="input-blocks mb-0">
                      <label>Confirm Password</label>
                      <div class="pass-group">
                        <input
                          style="background-position: 260px"
                          type="password"
                          :class="passwordClass"
                          v-model="confirmPassword"
                          @input="checkConfirmPassword"
                          class="pass-inputa"
                          name="confirm-password"
                        />
                        <span class="fas toggle-passworda fa-eye-slash"></span>
                      </div>

                      <!-- error message -->
                      <div v-if="confirmPassword && password">
                        <p
                          v-if="!confirmPasswordValidity"
                          style="margin-top: 10px; color: red"
                        >
                          The two passwords do not match
                        </p>
                        <p v-else style="margin-top: 10px; color: green">
                          The password fields match
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="text-end mb-3">
          <button type="button" @click="cancel" class="btn btn-cancel me-2">
            Cancel
          </button>
          <button type="submit" class="btn btn-submit">Save Employee</button>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import axiosJSONInstance from "@/api/axiosJSONInstance";
import { fetchStores } from "@/api/stores";
import Swal from "sweetalert2";
import { ref } from "vue";
const currentDate = ref(new Date());
const currentDateOne = ref(new Date());
export default {
  data() {
    return {
      first_name: null,
      last_name: null,
      email: null,
      contact_number: null,
      stores: [],
      storesSelect: [],
      storesDict: {},
      password: "",
      confirmPassword: "",
      confirmPasswordValidity: true,
      adminProfileId: "",
      passwordClass: "",
      collapsed: false,
    };
  },
  methods: {
    toggleCollapse() {
      const collapseHeader = this.$refs.collapseHeader;
      if (collapseHeader) {
        this.collapsed = !this.collapsed;
        collapseHeader.classList.toggle("active");
        document.body.classList.toggle("header-collapse");
      }
    },

    updateStoresArray(event) {
      let newValue = event.text;
      if (!this.stores.includes(newValue)) this.stores.push(newValue);
    },

    removeStore(store) {
      this.stores = this.stores.filter((st) => st !== store);
    },

    checkConfirmPassword() {
      this.confirmPasswordValidity = this.password == this.confirmPassword;
      if (this.confirmPassword && this.password)
        this.passwordClass = this.confirmPasswordValidity
          ? "form-control is-valid"
          : "form-control is-invalid";
      else this.passwordClass = "";
    },

    saveEmployee(event) {
      event.preventDefault();
      let data = {
        first_name: this.first_name,
        last_name: this.last_name,
        phone_contact: this.contact_number,
        store_ids: this.stores.map((store) => this.storesDict[store]),
        admin_profile: this.adminProfileId,
        user: {
          email: this.email,
          password: this.password,
        },
      };
      axiosJSONInstance.post("/api/v1/auth/staff/profile/", data).then(() => {
        Swal.fire({
          title: "Employee saved",
          text: "Employee been saved successfully.",
          icon: "success",
          confirmButtonText: "Okay",
        });
        this.$router.push("/hrm/employees-list");
      });
    },
    cancel() {
      this.$router.push("/hrm/employees-list");
    },
  },

  mounted() {
    fetchStores().then((data) => {
      for (let store of data) {
        this.storesSelect.push(store.full_name)
        this.storesDict[store.full_name] = store.id
      }
      this.adminProfileId = data[0].admin_profile;
    })
    .catch((e) => console.e)
  }

};
</script>

<style>
.stores {
  display: flex;
  gap: 10px;
  max-width: 100%;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.store {
  background-color: #67748e;
  border-radius: 30px;
  padding: 3px 10px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
}

.select2-container--default
  .select2-results__option--highlighted[aria-selected] {
  background: #67748e !important;
  color: white;
  font-weight: bold;
}
</style>
