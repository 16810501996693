<template>
  <div class="account-page">
    <div class="account-content">
      <div class="login-wrapper login-new">
        <div class="login-content user-login">
          <div class="login-logo">
            <img src="@/assets/img/pos-logo.svg" alt="img" />
            <router-link to="/dashboard" class="login-logo logo-white">
              <img src="@/assets/img/logo-white.png" alt="" />
            </router-link>
          </div>
          <form @submit.prevent="submitForm" id="registrationForm">
            <div class="login-userset">
              <div class="login-userheading">
                <h3>Register</h3>
                <h4>Create New AREAPO POS Accounts</h4>
              </div>
              <div class="form-login">
                <label>Firstname</label>
                <div class="form-addons">
                  <input v-model="firstname" type="text" class="form-control" />
                  <img src="@/assets/img/icons/user-icon.svg" alt="img" />
                </div>
              </div>

              <div class="form-login">
                <label>Lastname</label>
                <div class="form-addons">
                  <input v-model="lastname" type="text" class="form-control" />
                  <img src="@/assets/img/icons/user-icon.svg" alt="img" />
                </div>
              </div>

              <div class="form-login">
                <label>Othernames</label>
                <div class="form-addons">
                  <input v-model="othername" type="text" class="form-control" />
                  <img src="@/assets/img/icons/user-icon.svg" alt="img" />
                </div>
              </div>

              <div class="form-login">
                <label>Email Address</label>
                <div class="form-addons">
                  <input v-model="email" type="text" class="form-control" />
                  <img src="@/assets/img/icons/mail.svg" alt="img" />
                </div>
              </div>
              <div class="form-login">
                <label>Password</label>
                <div class="pass-group">
                  <input
                    v-model="password"
                    type="password"
                    class="pass-input"
                  />
                  <!-- <span class="fas toggle-password fa-eye-slash"></span> -->
                </div>
              </div>
              <div class="form-login">
                <label>Confirm Passworrd</label>
                <div class="pass-group">
                  <input
                    v-model="confirmPassword"
                    type="password"
                    class="pass-inputs"
                  />
                  <!-- <span class="fas toggle-passwords fa-eye-slash"></span> -->
                </div>
              </div>
              <div class="form-login authentication-check">
                <div class="row">
                  <div class="col-sm-8">
                    <div
                      class="custom-control custom-checkbox justify-content-start"
                    >
                      <div class="custom-control custom-checkbox">
                        <label class="checkboxs ps-4 mb-0 pb-0 line-height-1">
                          <input type="checkbox" />
                          <span class="checkmarks"></span>I agree to the
                          <a href="javascript:void(0);" class="hover-a"
                            >Terms & Privacy</a
                          >
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-login">
                <button type="submit" class="btn btn-login">Sign Up</button>
              </div>
              <div class="signinform">
                <h4>
                  Already have an account ?
                  <router-link to="/sign-in" class="hover-a"
                    >Sign In Instead</router-link
                  >
                </h4>
              </div>
              <!-- <div class="form-setlogin or-text">
                <h4>OR</h4>
              </div>
              <div class="form-sociallink">
                <ul class="d-flex">
                  <li>
                    <a href="javascript:void(0);" class="facebook-logo">
                      <img src="@/assets/img/icons/facebook-logo.svg" alt="Facebook" />
                    </a>
                  </li>
                  <li>
                    <a href="javascript:void(0);">
                      <img src="@/assets/img/icons/google.png" alt="Google" />
                    </a>
                  </li>
                  <li>
                    <a href="javascript:void(0);" class="apple-logo">
                      <img src="@/assets/img/icons/apple-logo.svg" alt="Apple" />
                    </a>
                  </li>
                </ul>
              </div> -->
            </div>
          </form>
        </div>
        <div
          class="my-4 d-flex justify-content-center align-items-center copyright-text"
        >
          <p>
            Copyright &copy; {{ new Date().getFullYear() }} AREAPO POS. All
            rights reserved.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { signUp } from "@/api/signup";
import { ref } from "vue";
export default {
  setup() {
    const email = ref("");
    const password = ref("");
    const confirmPassword = ref("");
    const firstname = ref("");
    const lastname = ref("");
    const othername = ref("");

    return { firstname, lastname, othername, email, password, confirmPassword };
  },

  methods: {
    async submitForm() {
      console.log(this.firstname);
      const data = {
        user: {
          email: this.email,
          password: this.password,
        },
        first_name: this.firstname,
        last_name: this.lastname,
        other_names: this.othername,
      };

      console.log(data);

      try {
        const response = await signUp(data);
        if (response) {
          this.$router.push("/sign-in");
        }
      } catch (error) {
        console.error("Error during sign up:", error);
      }
    },
  },
};
</script>

<style scoped>
#registrationForm {
  width: 50vw;
}

@media screen and (max-width: 575px) {
  #registrationForm {
    width: auto;
  }
}
</style>
