import { reactive } from 'vue';

// Retrieve the saved store state from local storage, or initialize it
const savedState = localStorage.getItem('storeState');
const initialState = savedState ? JSON.parse(savedState) : {
  id: "",
  full_name: "",
  store_name: "",
  email: "",
  contact: "",
  branch_name: "",
  location: "",
  created_at: "",
  updated_at: "",
  admin_profile: ""
};

// Create a reactive state for the selected store
export const selectedStoreState = reactive(initialState);

// Function to save the store state to local storage
export function updateSelectedStoreState(newStoreState) {
  Object.assign(selectedStoreState, newStoreState); // Assuming selectedStoreState is a reactive variable
  localStorage.setItem('storeState', JSON.stringify(newStoreState));
}


// Function to clear the store state and reset it
export function clearSelectedStoreState() {
  localStorage.removeItem('storeState');
  Object.assign(selectedStoreState, {
    id: "",
    full_name: "",
    store_name: "",
    email: "",
    contact: "",
    branch_name: "",
    location: "",
    created_at: "",
    updated_at: "",
    admin_profile: ""
  });
}
