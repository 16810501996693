import axiosJSONInstance from "./axiosJSONInstance";

export const fetchStoreSales = async (storeId) => {
  try {
    const response = await axiosJSONInstance.get(
      `/api/v1/stores/${storeId}/sales/`
    );

    return response.data.data;
  } catch (error) {
    console.error("Error fetching sales ", error);
    throw error;
  }
};
