import axiosJSONInstance from "./axiosJSONInstance";

export const deleteSaleItem = async (saleId, itemId) => {
  try {
    const response = await axiosJSONInstance.delete(
      `/api/v1/stores/sale/${saleId}/items/${itemId}/`
    );

    return response.data.data;
  } catch (error) {
    console.error("Error deleting sale item line", error);
    throw error;
  }
};
