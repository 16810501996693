<template>
  <layout-header></layout-header>
  <layout-sidebar></layout-sidebar>
  <div class="page-wrapper">
    <div class="content">
      <div class="page-header">
        <div class="add-item d-flex">
          <div class="page-title">
            <h4>Employees</h4>
            <h6>Manage your employees</h6>
          </div>
        </div>
        <ul class="table-top-head">
          <!-- <li>
            <a data-bs-toggle="tooltip" data-bs-placement="top" title="Pdf"
              ><img src="@/assets/img/icons/pdf.svg" alt="img"
            /></a>
          </li>
          <li>
            <a data-bs-toggle="tooltip" data-bs-placement="top" title="Excel"
              ><img src="@/assets/img/icons/excel.svg" alt="img"
            /></a>
          </li>
          <li>
            <a data-bs-toggle="tooltip" data-bs-placement="top" title="Print"
              ><i data-feather="printer" class="feather-printer"></i
            ></a>
          </li> -->
          <li>
            <a data-bs-toggle="tooltip" data-bs-placement="top" title="Refresh"
              ><i data-feather="rotate-ccw" class="feather-rotate-ccw"></i
            ></a>
          </li>
          <li>
            <a
              ref="collapseHeader"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Collapse"
              @click="toggleCollapse"
            >
              <i
                data-feather="chevron-up"
                :style="collapsed ? { transform: 'rotate(180deg)' } : {}"
                class="feather-chevron-up"
              ></i>
            </a>
          </li>
        </ul>
        <div class="page-btn">
          <router-link to="add-employee" class="btn btn-added"
            ><vue-feather type="plus-circle" class="me-2"></vue-feather>Add New
            Employee</router-link
          >
        </div>
      </div>
      <!-- /employee list -->
      <div class="card table-list-card">
        <div class="card-body pb-0">
          <div class="table-top table-top-two table-top-new">
            <div class="search-set mb-0">
              <div class="total-employees">
                <h6>
                  <i data-feather="users" class="feather-user"></i>Total
                  Employees
                  <span>{{ employee_count }}</span>
                </h6>
              </div>
              <!-- <div class="search-input">
                <a href="" class="btn btn-searchset"
                  ><i data-feather="search" class="feather-search"></i
                ></a>
                <input type="search" class="form-control" />
              </div> -->
            </div>
            <div class="search-path d-flex align-items-center search-path-new">
              <div class="d-flex">
                <!-- <a
                  class="btn btn-filter"
                  id="filter_search"
                  v-on:click="toggleFilterSearch"
                  :class="{ setclose: filter }"
                >
                  <vue-feather type="filter" class="filter-icon"></vue-feather>
                  <span
                    ><img src="@/assets/img/icons/closes.svg" alt="img"
                  /></span>
                </a> -->
                <router-link to="/hrm/employees-list" class="btn-list active"
                  ><vue-feather type="list" class="list"></vue-feather
                ></router-link>
                <router-link to="/hrm/employees-grid" class="btn-grid"
                  ><vue-feather type="grid" class="grid"></vue-feather
                ></router-link>
              </div>

              <!-- <div class="form-sort">
                <vue-feather type="sliders" class="info-img"></vue-feather>
                <vue-select
                  :options="SortEmpList"
                  id="sortemplist"
                  placeholder="Sort by Date"
                />
              </div> -->
            </div>
          </div>
          <!-- /Filter -->
          <div
            class="card"
            :style="{ display: filter ? 'block' : 'none' }"
            id="filter_inputs"
          >
            <div class="card-body pb-0">
              <div class="row">
                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="input-blocks">
                    <vue-feather type="user" class="info-img"></vue-feather>
                    <vue-select
                      :options="EmpNameList"
                      id="empnamelist"
                      placeholder="Choose Name"
                      @select="filterEmployees"
                      v-model="empNameSelected"
                    />
                  </div>
                </div>
                <!-- <div class="col-lg-3 col-sm-6 col-12">
                  <div class="input-blocks">
                    <vue-feather type="stop-circle" class="info-img"></vue-feather>
                    <vue-select
                      :options="EmpstatusList"
                      id="empstatuslist"
                      placeholder="Choose Status"
                    />
                  </div>
                </div> -->
                <!-- <div class="col-lg-3 col-sm-6 col-12 ms-auto">
                  <div class="input-blocks">
                    <a class="btn btn-filters ms-auto">
                      <i data-feather="search" class="feather-search"></i> Search
                    </a>
                  </div>
                </div> -->
              </div>
            </div>
          </div>
          <!-- /Filter -->
          <div class="table-responsive">
            <a-table
              class="table datanew"
              :columns="columns"
              :data-source="employeesList"
            >
              <template #bodyCell="{ column, record }">
                <template v-if="column.key === 'full_name'">
                  <div class="userimgname">
                    <a href="javascript:void(0);" class="product-img">
                      <img
                        :src="require(`@/assets/img/users/user-03.jpg`)"
                        alt="product"
                      />
                    </a>
                    <div>
                      <a href="javascript:void(0);">{{ record.full_name }}</a>
                      <span class="emp-team">{{ record.role }}</span>
                    </div>
                  </div>
                </template>
                <template v-else-if="column.key === 'Status'">
                  <span class="badges-success">{{ "ACTIVE" }}</span>
                </template>
                <template v-else-if="column.key === 'action'">
                  <td class="action-table-data">
                    <div class="edit-delete-action">
                      <a class="me-2 p-2" href="javascript:void(0);">
                        <i data-feather="eye" class="feather-eye"></i>
                      </a>
                      <router-link
                        @click="setEditUser(record.id)"
                        class="me-2 p-2"
                        to="edit-employee"
                      >
                        <i data-feather="edit" class="feather-edit"></i>
                      </router-link>
                      <a
                        class="confirm-text p-2"
                        @click="showConfirmation(record.id)"
                        href="javascript:void(0);"
                      >
                        <i data-feather="trash-2" class="feather-trash-2"></i>
                      </a>
                    </div>
                  </td>
                </template>
              </template>
            </a-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
const columns = [
  {
    title: "Employee",
    dataIndex: "full_name",
    key: "full_name",
    sorter: {
      compare: (a, b) => {
        a = a.Employee.toLowerCase();
        b = b.Employee.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Email",
    dataIndex: "email",
    sorter: {
      compare: (a, b) => {
        a = a.Email.toLowerCase();
        b = b.Email.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Phone Number",
    dataIndex: "phone_contact",
    sorter: {
      compare: (a, b) => {
        a = a.phone_contact.toLowerCase();
        b = b.phone_contact.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Joined",
    dataIndex: "createdAt",
    sorter: {
      compare: (a, b) => {
        a = a.createdAt.toLowerCase();
        b = b.createdAt.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Action",
    key: "action",
  },
];

const data = [];
import axiosJSONInstance from "@/api/axiosJSONInstance";
import { formatTime } from "@/assets/js/utils";
import Swal from "sweetalert2";
export default {
  data() {
    return {
      filter: false,
      EmpNameList: ["Choose Name"],
      SortEmpList: ["Sort by Date", "Newest", "Oldest"],
      columns,
      employeesList: [],
      employee_count: null,
      allEmployees: [],
      empNameSelected: "",
      collapsed: false,
    };
  },
  methods: {
    showConfirmation(empId) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.deleteEmployee(empId)
            .then((res) => {
              Swal.fire({
                icon: "success",
                title: "Deleted!",
                text: "Employee has been deleted.",
                confirmButtonClass: "btn btn-success",
              });
              this.employeesList = this.employeesList.filter(
                (em) => em.id !== empId
              );
              this.employee_count = this.employeesList.length;
            })
            .catch((e) => {
              console.log(e);
              Swal.fire({
                icon: "error",
                title: "Error",
                text: "Something went wrong.",
                confirmButtonClass: "btn btn-danger",
              });
            });
        }
      });
    },
    toggleCollapse() {
      const collapseHeader = this.$refs.collapseHeader;

      if (collapseHeader) {
        this.collapsed = !this.collapsed;
        collapseHeader.classList.toggle("active");
        document.body.classList.toggle("header-collapse");
      }
    },
    fetchEmployees() {
      axiosJSONInstance
        .get("/api/v1/auth/staff/profile/")
        .then((response) => {
          let emps = [];
          for (let emp of response.data.data) {
            emps.push({
              full_name: `${emp.first_name} ${emp.last_name}`,
              role: emp.user.role,
              email: emp.user.email,
              phone_contact: emp.phone_contact,
              createdAt: formatTime(emp.created_at),
              id: emp.id,
            });
            this.EmpNameList.push(`${emp.first_name} ${emp.last_name}`);
          }
          this.employeesList = emps;
          this.allEmployees = emps;
          this.employee_count = this.employeesList.length;
        })
        .catch((e) => {
          this.employeesList = [];
          console.log(e);
        });
    },
    filterEmployees() {
      this.employeesList = this.allEmployees;
      if (this.empNameSelected && this.empNameSelected !== "Choose Name")
        this.employeesList = this.employeesList.filter((e) =>
          e.full_name.includes(this.empNameSelected)
        );
      else this.employeesList = this.allEmployees;
    },
    toggleFilterSearch() {
      this.filter = !this.filter;
      this.employeesList = this.allEmployees;
      this.empNameSelected = "";
    },
    setEditUser(id) {
      localStorage.setItem("editUserId", id);
      this.$router.push("/hrm/edit-employee");
    },
    deleteEmployee(empId) {
      return axiosJSONInstance.delete(`/api/v1/auth/staff/profile/${empId}/`);
    },
  },

  mounted() {
    this.fetchEmployees();
  },
};
</script>
