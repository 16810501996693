<template>
  <div class="modal fade" id="sales-detail-new">
    <!-- <div v-else-if="!loading && errorMessage" class="col-md-12 text-center">
                        <h5>{{ errorMessage }}</h5>
                    </div> -->
    <div class="modal-dialog sales-details-modal">
      <div class="modal-content">
        <div class="spinner-container d-flex justify-content-center align-items-center" style="height: 50vh"
          v-if="loading">
          <div class="spinner-grow text-primary" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
        <div v-else class="page-wrapper details-blk">
          <div class="content p-0">
            <div class="page-header p-4 mb-0">
              <div class="add-item d-flex">
                <div class="page-title modal-datail">
                  <h4>Sales Detail : {{ data1.reference_id }}</h4>
                </div>
              </div>
              <ul class="table-top-head">
                <!-- <li>
                  <a
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Pdf"
                    ><vue-feather
                      type="edit"
                      class="action-edit sales-action"
                    ></vue-feather
                  ></a>
                </li> -->
                <div class="page-btn">
                  <button class="btn btn-added" @click="updateLineItem()" :disabled="!enabled">
                    <vue-feather type="save" class="me-2"></vue-feather> Save
                  </button>
                </div>
              </ul>
            </div>

            <div class="card">
              <div class="card-body">
                <form @submit.prevent="submitForm">
                  <div class="invoice-box table-height" style="
                      max-width: 1600px;
                      width: 100%;
                      overflow: auto;
                      padding: 0;
                      font-size: 14px;
                      line-height: 24px;
                      color: #555;
                    ">
                    <div class="sales-details-items d-flex">
                      <div class="details-item">
                        <h6>Customer Info</h6>
                        <p v-if="data1.customer_profile?.first_name?.toLowerCase() != 'walk in'">
                          {{ data1.customer_profile.first_name }}
                          {{ data1.customer_profile.last_name }}<br />
                          {{ data1.customer_profile.email_address }}<br />
                          {{ data1.customer_profile.phone_contact }}<br />
                        </p>
                        <p v-else>
                          Walk In Customer
                        </p>
                      </div>

                      <div class="details-item">
                        <h6>Invoice Info</h6>
                        <p>Payment Method - {{ data1.payment_method }}<br /></p>
                      </div>
                      <div class="details-item">
                        <h5>
                          Paid<br />
                          Completed
                        </h5>
                      </div>
                    </div>
                    <h5 class="order-text">Order Summary</h5>
                    <div class="table-responsive">
                      <a-table :columns="columns" :data-source="data2">
                        <template #bodyCell="{ column, record }">
                          <template v-if="column.key === 'Status'">
                            <div>
                              <span :class="record.Status_class">{{
                                record.Status
                              }}</span>
                            </div>
                          </template>
                          <template v-if="column.key === 'Quantity'">
                            <div class="product-quantity">
                              <span class="quantity-btn"><vue-feather type="minus-circle" class="search" @click="
                                subtractQuantity(record.id, record.quantity)
                                "></vue-feather></span>
                              <input type="text" class="quntity-input" v-model.number="record.quantity" />
                              <span class="quantity-btn">+<vue-feather type="plus-circle" class="plus-circle" @click="
                                addQuantity(record.id, record.quantity)
                                "></vue-feather></span>
                            </div>
                          </template>

                          <template v-else-if="column.key === 'Payment_Status'">
                            <div>
                              <span :class="record.Payment_Class">{{
                                record.Payment_Status
                              }}</span>
                            </div>
                          </template>
                          <template v-else-if="column.key === 'action'">
                            <div class="text-center">
                              <a class="confirm-text p-2 delete-button" @click="setItemId(record.id)"
                                href="javascript:void(0);">
                                <vue-feather type="trash-2" class="trash-2" stroke="red"></vue-feather>
                              </a>
                              <ul class="dropdown-menu sales-list-icon">
                                <li>
                                  <a href="javascript:void(0);" class="dropdown-item" data-bs-toggle="modal"
                                    data-bs-target="#sales-details-new" @click="showrecordid(record.id)"><vue-feather
                                      type="eye" class="info-img"></vue-feather>Sale Detail</a>
                                </li>
                                <li>
                                  <a href="javascript:void(0);" class="dropdown-item" data-bs-toggle="modal"
                                    data-bs-target="#edit-sales-new"><vue-feather type="edit"
                                      class="info-img"></vue-feather>Edit Sale</a>
                                </li>
                                <li>
                                  <a href="javascript:void(0);" class="dropdown-item" data-bs-toggle="modal"
                                    data-bs-target="#showpayment"><vue-feather type="dollar-sign"
                                      class="info-img"></vue-feather>Show Payments</a>
                                </li>
                                <li>
                                  <a href="javascript:void(0);" class="dropdown-item" data-bs-toggle="modal"
                                    data-bs-target="#createpayment"><vue-feather type="plus-circle"
                                      class="info-img"></vue-feather>Create Payment</a>
                                </li>
                                <li>
                                  <a href="javascript:void(0);" class="dropdown-item"><vue-feather type="download"
                                      class="info-img"></vue-feather>Download pdf</a>
                                </li>
                                <li>
                                  <a href="javascript:void(0);" class="dropdown-item confirm-text mb-0 delete-button"
                                    @click="setItemId(record.id)"><vue-feather type="trash-2"
                                      class="info-img"></vue-feather>Delete Sale</a>
                                </li>
                              </ul>
                            </div>
                          </template>
                        </template>
                      </a-table>
                    </div>
                  </div>

                  <div class="row">
                    <div class="row">
                      <div class="col-lg-6 ms-auto">
                        <div class="total-order w-100 max-widthauto m-auto mb-4">
                          <ul>
                            <li>
                              <h4>Sub Total</h4>
                              <h5>GH₵ {{ data1.sub_total }}</h5>
                            </li>

                            <li>
                              <h4>Discount Type</h4>
                              <h5>{{ data1.discount_type }}</h5>
                            </li>
                            <li>
                              <h4>Discount Value</h4>
                              <h5>GH₵ {{ data1.discount_value }}</h5>
                            </li>
                            <li>
                              <h4>Grand Total</h4>
                              <h5>GH₵ {{ data1.total_bill }}</h5>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { fetchSaleDetails } from "@/api/fetchSaleDetail";
import { deleteSaleItem } from "@/api/deleteSaleItem";
import { updateSaleItem } from "@/api/updateSaleItem";
import { selectedStoreState } from "@/state/storeState";
const columns = [
  {
    title: "Name",
    // key: "firstName",
    // dataIndex: "customer_profile",
    dataIndex: "product",
    sorter: {
      compare: (a, b) => {
        a = a.product.toLowerCase();
        b = b.product.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },

  // {
  //   title: "Reference",
  //   dataIndex: "id",
  //   sorter: {
  //     compare: (a, b) => {
  //       a = a.id.toLowerCase();
  //       b = b.id.toLowerCase();
  //       return a > b ? -1 : b > a ? 1 : 0;
  //     },
  //   },
  // },
  {
    title: "Quantity",
    dataIndex: "quantity",
    key: "Quantity",
    sorter: {
      compare: (a, b) => {
        a = a.quantity.toLowerCase();
        b = b.quantity.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  // {
  //   title: "Status",
  //   dataIndex: "Status",
  //   key: "Status",
  //   sorter: {
  //     compare: (a, b) => {
  //       a = a.Status.toLowerCase();
  //       b = b.Status.toLowerCase();
  //       return a > b ? -1 : b > a ? 1 : 0;
  //     },
  //   },
  // },
  {
    title: "Unit Price",
    dataIndex: "unit_price",
    sorter: {
      compare: (a, b) => {
        a = a.unit_price.toString().toLowerCase();
        b = b.unit_price.toString().toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  // {
  //   title: "Paid",
  //   dataIndex: "Paid",
  //   sorter: {
  //     compare: (a, b) => {
  //       a = a.Paid.toLowerCase();
  //       b = b.Paid.toLowerCase();
  //       return a > b ? -1 : b > a ? 1 : 0;
  //     },
  //   },
  // },
  // {
  //   title: "Due",
  //   dataIndex: "Due",
  //   sorter: {
  //     compare: (a, b) => {
  //       a = a.Due.toLowerCase();
  //       b = b.Due.toLowerCase();
  //       return a > b ? -1 : b > a ? 1 : 0;
  //     },
  //   },
  // },
  // {
  //   title: "Payment Status",
  //   dataIndex: "Payment_Status",
  //   key: "Payment_Status",
  //   sorter: {
  //     compare: (a, b) => {
  //       a = a.Payment_Status.toLowerCase();
  //       b = b.Payment_Status.toLowerCase();
  //       return a > b ? -1 : b > a ? 1 : 0;
  //     },
  //   },
  // },
  {
    title: "Action",
    key: "action",
    sorter: true,
  },
];
const data = [];
export default {
  props: {
    selectedSaleId: {
      type: String,
      required: true,
    },
    data1: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      columns,
      data,
      data2: this.data1,
      loading: true,
      itemId: "",
      enabled: false,
    };
  },
  watch: {
    data1(newVal) {
      this.data2 = newVal; // Update the data property when the prop changes
      console.log(this.data1);
      console.log(this.data2);
      this.data2 = this.data2.sale_items;
      console.log(this.data2);
      this.loading = false;
      //   this.data=newVal;
      //   console.log(this.data)
    },
  },
  // computed: {
  //   // Compare the current state (data2) with the original state (data1.sale_items)
  //   isDataModified() {
  //     return JSON.stringify(this.data2.) !== JSON.stringify(this.data2.sale_items);
  //   },
  // },

  setup() {
    return {};
  },
  methods: {
    async fetchDetail() {
      try {
        const sales = await fetchSaleDetails(
          selectedStoreState.id,
          this.selectedSaleId
        );
        this.data = sales;
        // console.log(this.data);
        this.data2 = this.data.sale_items;
        console.log(this.data2);
        // console.log(sales[0].customer_profile.first_name);
        // console.log(this.columns[1].dataIndex)
        // k=this.columns[2].dataIndex
        // console.log(sales[0].this.columns[2].dataIndex)
      } catch (error) {
        this.data = [];
        this.emptyText = "Error: " + error.message;
        Swal.fire({
          title: "Unable To Fetch Products",
          text: error?.response?.data?.message || error.message,
          icon: "error",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
        });
      } finally {
        this.loading = false; // Hide loader after fetching data
      }
    },
    deleteLineItem() {
      deleteSaleItem(this.selectedSaleId, this.itemId)
        .then(() => {
          Swal.fire({
            title: "Product Deleted",
            text: "The product was successfully deleted.",
            icon: "success",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
          });
          this.fetchDetail();
        })
        .catch((error) => {
          this.emptyText = "Error: " + error.message;
          Swal.fire({
            title: "Unable To Delete Product",
            text: error?.response?.data?.message || error.message,
            icon: "error",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
          });
        });
    },
    updateLineItem() {
      updateSaleItem(this.selectedSaleId, this.data2)
        .then(() => {
          Swal.fire({
            title: "Item(s) Have Been Updated",
            text: "The product was successfully updated.",
            icon: "success",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
          });
          this.fetchDetail();
          this.enabled = false; // Disable the Save button after updating
        })
        .catch((error) => {
          this.emptyText = "Error: " + error.message;
          Swal.fire({
            title: "Unable To Update Line Items",
            text: error.response.data.message,
            icon: "error",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 6000,
          });
        });
    },
    setItemId(id) {
      this.itemId = id;
      this.deleteLineItem();
    },
    addQuantity(saleItemId, currentQuantity) {
      for (let i = 0; i < this.data2.length; i++) {
        if (this.data2[i].id === saleItemId) {
          console.log(this.data2[i].quantity);
          console.log(this.data2[i].product);
          this.data2[i].quantity += 1;
          this.enabled = true;
          console.log(this.data2);
        }
      }
    },
    subtractQuantity(saleItemId, currentQuantity) {
      for (let i = 0; i < this.data2.length; i++) {
        if (this.data2[i].id === saleItemId) {
          // console.log(this.data2[i].quantity)
          // console.log(this.data2[i].product)
          this.data2[i].quantity -= 1;
          this.enabled = true;
          console.log(this.data2);
        }
      }
    },
  },
};
</script>

<style scoped>
a.delete-button {
  border: 1px solid rgba(136, 41, 104, 0.3);
  background-color: #ffffff;
  border-radius: 8px;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  padding: 8px;
}

a.delete-button:hover {
  background-color: #e60e8c;
  border-color: #092c4c;
}

a.delete-button:hover i svg,
a.delete-button:hover i {
  stroke: #ffffff !important;

}
</style>
