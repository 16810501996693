<template>
  <!-- Add Store -->
  <div class="modal fade" id="add-stores">
    <div class="modal-dialog modal-dialog-centered custom-modal-two">
      <div class="modal-content">
        <div class="page-wrapper-new p-0">
          <div class="content">
            <div
              class="modal-header border-0 custom-modal-header d-flex align-items-center justify-content-between"
            >
              <div class="page-title">
                <h4>{{ isEditMode ? "Edit Store" : "Create Store" }}</h4>
              </div>
              <button
                type="button"
                class="close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body custom-modal-body">
              <div v-if="loading" class="p-5">
                <div class="spinner-grow text-primary" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
              <form v-else @submit.prevent="submitForm">
                <div class="mb-3">
                  <label class="form-label">Store Name</label>
                  <input
                    type="text"
                    v-model="storeData.store_name"
                    class="form-control"
                  />
                </div>
                <div class="mb-3">
                  <label class="form-label">Branch Name</label>
                  <input
                    type="text"
                    v-model="storeData.branch_name"
                    class="form-control"
                  />
                </div>
                <div class="mb-3">
                  <label class="form-label">Email</label>
                  <input
                    type="email"
                    v-model="storeData.email"
                    class="form-control"
                  />
                </div>
                <div class="mb-3">
                  <label class="form-label">Phone Contact</label>
                  <input
                    type="text"
                    v-model="storeData.contact"
                    class="form-control"
                  />
                </div>
                <div class="mb-3">
                  <label class="form-label">Location</label>
                  <input
                    type="text"
                    v-model="storeData.location"
                    class="form-control"
                  />
                </div>
                <label class="form-label">Store Logo</label>
                <div class="d-flex align-items-center gap-3 mb-3">
                  <div class="store-img ml-0" v-if="storeImage.imagePreviewURL">
                    <img :src="storeImage.imagePreviewURL" alt="image" />
                    <a @click="removeStoreImage" class="x-store-image"
                      ><vue-feather type="x"></vue-feather
                    ></a>
                  </div>

                  <div class="image-upload mb-0">
                    <input type="file" @change="handleImageInputChange" />
                    <div class="image-uploads">
                      <h4>
                        {{
                          storeImage.imagePreviewURL
                            ? "Change Image"
                            : "Add Image"
                        }}
                      </h4>
                    </div>
                  </div>
                </div>
                <div class="modal-footer-btn">
                  <button
                    type="button"
                    class="btn btn-cancel me-2"
                    data-bs-dismiss="modal"
                  >
                    Cancel
                  </button>
                  <button type="submit" class="btn btn-submit">
                    {{ isEditMode ? "Save Changes" : "Create" }}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Add Store -->
  <full-page-loader :loading="fullLoading"></full-page-loader>
</template>

<style scoped>
.image-upload {
  height: 50px;
  width: 150px;
  padding: 0;
  background: var(--color-primary);
  transition: all 0.5s;
}

.image-upload:hover {
  background: var(--color-primary-hover);
}

.image-upload input[type="file"] {
  height: 100%;
}

.image-upload .image-uploads {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  cursor: pointer;
  height: 100%;
}

.image-upload .image-uploads h4 {
  color: white;
}
</style>

<script>
import {
  createAdminStore,
  removeStoreLogo,
  updateStoreDetails,
} from "@/api/stores";

export default {
  data() {
    return {
      fullLoading: false,
      storeData: {
        store_name: "",
        branch_name: "",
        email: "",
        contact: "",
        location: "",
        logo: null,
      },
      loading: false,
      storeImage: {
        imagePreviewURL: "",
        imageFileName: "",
        imageFileType: "",
        imageFile: null,
      },
    };
  },
  props: {
    isEditMode: false,
    editStoreData: {
      type: Object,
      default: () => ({
        id: "",
        store_name: "",
        branch_name: "",
        email: "",
        contact: "",
        location: "",
        logo: null,
      }),
    },
  },
  mounted() {
    const storeModal = document.getElementById("add-stores");

    storeModal.addEventListener("shown.bs.modal", () => {
      this.storeImage.imagePreviewURL = this.editStoreData.logo;
    });

    storeModal.addEventListener("hidden.bs.modal", () => {
      this.storeImage.imagePreviewURL = "";
    });
  },
  methods: {
    submitForm() {
      let formData = new FormData();

      for (let key in this.storeData) {
        if (key === "logo" && this.storeImage.imageFile) {
          formData.append(
            "logo",
            this.storeImage.imageFile,
            this.storeImage.imageFileName
          );
        } else {
          formData.append(key, this.storeData[key]);
        }
      }

      if (!this.storeImage.imageFile) {
        formData.delete("logo");
      }

      const apiCall = this.isEditMode
        ? updateStoreDetails(this.storeData.id, formData)
        : createAdminStore(formData);

      // show loader
      this.fullLoading = true;

      apiCall
        .then((data) => {
          // hide modal
          document.getElementById("add-stores").querySelector(".close").click();

          this.$emit("store-modal-closed");

          Swal.fire({
            title: this.isEditMode
              ? "Store Updated Successfully"
              : `New store created: ${data.store_name}`,
            icon: "success",
            toast: true,
            position: "top-end",
            timer: 5000,
            showConfirmButton: false,
            showCloseButton: false,
          });

          // reset field
          this.storeData = {
            id: "",
            store_name: "",
            branch_name: "",
            email: "",
            contact: "",
            location: "",
            logo: null,
          };
        })
        .catch((error) => {
          Swal.fire({
            title: "Error",
            text: error.response?.data?.message || error.message,
            icon: "error",
          });
        })
        .finally(() => {
          this.fullLoading = false;
        });
    },
    handleImageInputChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;

      // Define an array of accepted image types
      const acceptedTypes = [
        "image/jpeg",
        "image/png",
        "image/gif",
        "image/webp",
        "image/bmp",
      ];

      // Get the file type
      const fileType = files[0].type;

      // Check if the file type is in the accepted types array
      if (!acceptedTypes.includes(fileType)) {
        Swal.fire({
          title: "Invalid image file type.",
          text: "Supported file types are: JPEG, PNG, GIF, WebP, BMP",
          icon: "error",
        });
        return;
      }

      this.createImage(files[0]);
    },
    createImage(file) {
      var reader = new FileReader();
      reader.onload = (e) => {
        this.storeImage.imagePreviewURL = e.target.result;
      };
      this.storeImage.imageFileName = file.name;
      this.storeImage.imageFileType = file.type;
      this.storeImage.imageFile = file; // Store the actual file object
      reader.readAsDataURL(file);
    },
    removeStoreImage() {
      // Show alert asking user to confirm to remove store logo.
      Swal.fire({
        title: "Are you sure you want to remove this store's logo?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        confirmButtonText: "Yes, remove it!",
        cancelButtonColor: "#3085d6",
        confirmButtonClass: "btn btn-danger ml-1",
        cancelButtonClass: "btn btn-outline-primary",
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.fullLoading = true;

          // make API call to delete store image
          removeStoreLogo(this.storeData.id)
            .then((data) => {
              this.storeImage.imagePreviewURL = "";
              this.storeImage.imageFileName = "";
              this.storeImage.imageFileType = "";
              this.storeImage.imageFile = null; // Clear the file object
            })
            .catch((error) => {
              Swal.fire({
                title: "Error",
                text: error.response?.data?.message || error.message,
                icon: "error",
              });
            })
            .finally(() => {
              this.fullLoading = false;
            });
        }
      });
    },
  },
  watch: {
    editStoreData(newVal, oldVal) {
      this.storeData = { ...newVal };
    },
  },
};
</script>

<style scoped>
.store-img {
  height: 150px;
  width: 150px;
  object-fit: cover;
  object-position: center;
}

.store-img img {
  max-width: unset;
  max-height: unset;
  width: 100%;
  height: 100%;
}

a.x-store-image {
  position: relative;
  bottom: 164px;
  left: 142px;
}

a.x-store-image i {
  background: red;
  color: white;
  border-radius: 3px;
}
</style>
