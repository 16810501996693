<template>
  <div id="logout-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-sm">
      <div class="modal-content">
        <div class="modal-body p-4">
          <div class="text-center">
            <i class="dripicons-warning h1 text-warning"></i>
            <h4 class="mt-2">Log Out</h4>
            <p class="mt-3">
              Are you sure you want to Log out ?
            </p>
            <button type="button" class="btn btn-warning my-2" data-bs-dismiss="modal" @click="logout">
              Continue
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { clearAuthState } from "@/state/authState";
import { clearSelectedStoreState } from "@/state/storeState";
export default {
  setup() {
    return {};
  },
  methods: {
    logout() {
      clearAuthState();
      clearSelectedStoreState();
      this.$router.push("/sign-in");
    },
  },
};
</script>
