<template>
  <layout-header></layout-header>
  <layout-sidebar></layout-sidebar>
  <full-page-loader :loading="loading"></full-page-loader>
  <div class="page-wrapper">
    <div class="content">
      <div class="page-header">
        <div class="add-item d-flex">
          <div v-if="!isEditMode" class="page-title">
            <h4>New Product</h4>
            <h6>Create new product</h6>
          </div>
          <div v-else class="page-title">
            <h4>Edit Product</h4>
            <h6>Modify product details</h6>
          </div>
        </div>
        <ul class="table-top-head">
          <li>
            <div class="page-btn">
              <router-link
                :to="`/inventory/product-list`"
                class="btn btn-secondary"
                ><vue-feather type="arrow-left" class="me-2"> </vue-feather>Back
                to Product List
              </router-link>
            </div>
          </li>
          <li>
            <a
              ref="collapseHeader"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Collapse"
              @click="toggleCollapse"
            >
              <i data-feather="chevron-up" class="feather-chevron-up"></i>
            </a>
          </li>
        </ul>
      </div>
      <!-- /add -->
      <form @submit.prevent="submitForm">
        <div class="card">
          <div class="card-body add-product pb-0">
            <div class="accordion-card-one accordion" id="accordionExample">
              <div class="accordion-item">
                <div class="accordion-header" id="headingOne">
                  <div
                    class="accordion-button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-controls="collapseOne"
                  >
                    <div class="addproduct-icon">
                      <h5>
                        <vue-feather type="info" class="add-info"></vue-feather
                        ><span>Product Information</span>
                      </h5>
                      <a href="javascript:void(0);"
                        ><vue-feather
                          type="chevron-down"
                          class="chevron-down-add"
                        ></vue-feather
                      ></a>
                    </div>
                  </div>
                </div>
                <div
                  id="collapseOne"
                  class="accordion-collapse collapse show"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    <!-- <div class="row">
                      <div class="col-lg-4 col-sm-6 col-12">
                        <div class="mb-3 add-product">
                          <label class="form-label">Store</label>
                          <vue-select
                            :options="ThomasStore"
                            id="thomasstore"
                            placeholder="Choose"
                          />
                        </div>
                      </div>
                      <div class="col-lg-4 col-sm-6 col-12">
                        <div class="mb-3 add-product">
                          <label class="form-label">Warehouse</label>
                          <vue-select
                            :options="WarhouseStore"
                            id="warehousestore"
                            placeholder="Choose"
                          />
                        </div>
                      </div>
                    </div> -->
                    <div class="row">
                      <div class="col-lg-4 col-sm-6 col-12">
                        <div class="mb-3 add-product">
                          <label class="form-label">Product Name</label>
                          <input
                            type="text"
                            class="form-control"
                            v-model="productData.product_name"
                          />
                        </div>
                      </div>
                      <div class="col-lg-4 col-sm-6 col-12">
                        <div class="mb-3 add-product">
                          <div class="add-newplus">
                            <label class="form-label">Category</label>
                            <a
                              href="javascript:void(0);"
                              data-bs-toggle="modal"
                              data-bs-target="#add-units-category"
                              ><vue-feather
                                type="plus-circle"
                                class="plus-down-add"
                              ></vue-feather
                              ><span>Add New</span></a
                            >
                          </div>
                          <vue-select
                            v-model="productData.category"
                            :options="categoryChoices"
                            id="choosenew"
                            placeholder="Choose"
                          />
                        </div>
                      </div>
                      <div class="col-lg-4 col-sm-6 col-12">
                        <div class="mb-3 add-product">
                          <div class="add-newplus">
                            <label class="form-label">Brand</label>
                            <a
                              href="javascript:void(0);"
                              data-bs-toggle="modal"
                              data-bs-target="#add-units-brand"
                              ><vue-feather
                                type="plus-circle"
                                class="plus-down-add"
                              ></vue-feather
                              ><span>Add New</span></a
                            >
                          </div>
                          <vue-select
                            v-model="productData.brand"
                            :options="brandChoices"
                            id="chooseadd"
                            placeholder="Choose"
                          />
                        </div>
                      </div>
                      <!-- <div class="col-lg-4 col-sm-6 col-12">
                        <div class="mb-3 add-product">
                          <label class="form-label">Slug</label>
                          <input type="text" class="form-control" />
                        </div>
                      </div> -->
                      <!-- <div class="col-lg-4 col-sm-6 col-12">
                        <div class="input-blocks add-product list">
                          <label>SKU</label>
                          <input
                            type="text"
                            class="form-control list"
                            placeholder="Enter SKU"
                          />
                          <button type="submit" class="btn btn-primaryadd">
                            Generate Code
                          </button>
                        </div>
                      </div> -->
                    </div>
                    <!-- <div class="addservice-info">
                      <div class="row">
                        <div class="col-lg-4 col-sm-6 col-12">
                          <div class="mb-3 add-product">
                            <label class="form-label">Sub Category</label>
                            <vue-select
                              :options="SubCategory"
                              id="subcategory"
                              placeholder="Choose"
                            />
                          </div>
                        </div>
                        <div class="col-lg-4 col-sm-6 col-12">
                          <div class="mb-3 add-product">
                            <label class="form-label">Sub Sub Category</label>
                            <vue-select
                              :options="SubSubCategory"
                              id="subsubcategory"
                              placeholder="Choose"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="add-product-new">
                      <div class="row">

                        <div class="col-lg-4 col-sm-6 col-12">
                          <div class="mb-3 add-product">
                            <label class="form-label">Selling Type</label>
                            <vue-select :options="SellingType" id="sellingtype" placeholder="Choose" />
                          </div>
                        </div>
                      </div>
                    </div> -->
                    <!-- <div class="row">
                      <div class="col-lg-6 col-sm-6 col-12">
                        <div class="mb-3 add-product">
                          <label class="form-label">Barcode Symbology</label>
                          <vue-select :options="ChooseCode" id="choosecode" placeholder="Choose" />
                        </div>
                      </div>
                      <div class="col-lg-6 col-sm-6 col-12">
                        <div class="input-blocks add-product list">
                          <label>Item Code</label>
                          <input type="text" class="form-control list" placeholder="Please Enter Item Code" />
                          <button type="submit" class="btn btn-primaryadd">
                            Generate Code
                          </button>
                        </div>
                      </div>
                    </div> -->
                    <!-- Editor -->
                    <div class="col-lg-12">
                      <div
                        class="input-blocks summer-description-box transfer mb-3"
                      >
                        <label>Description</label>
                        <textarea
                          v-model="productData.description"
                          class="form-control h-100"
                          rows="1"
                        ></textarea>
                        <p class="mt-1">Maximum 60 Characters</p>
                      </div>
                    </div>
                    <!-- /Editor -->
                  </div>
                </div>
              </div>
            </div>

            <div class="text-editor add-list add">
              <div class="col-lg-12">
                <div class="add-choosen gap-3">
                  <div
                    v-if="!productImage.imagePreviewURL"
                    class="input-blocks"
                  >
                    <div class="image-upload">
                      <input type="file" @change="handleImageInputChange" />
                      <div class="image-uploads">
                        <i
                          data-feather="plus-circle"
                          class="plus-down-add me-0"
                        ></i>
                        <h4>Add Product Image</h4>
                      </div>
                    </div>
                  </div>

                  <div
                    class="phone-img ml-0"
                    v-if="productImage.imagePreviewURL"
                  >
                    <img :src="productImage.imagePreviewURL" alt="image" />
                    <a href="javascript:void(0);" @click="removeProductImage"
                      ><vue-feather
                        type="x"
                        class="x-square-add remove-product"
                      ></vue-feather
                    ></a>
                  </div>
                </div>
              </div>
            </div>

            <!-- <div class="accordion-card-one accordion" id="accordionExample3">
              <div class="accordion-item">
                <div class="accordion-header" id="headingThree">
                  <div class="accordion-button" data-bs-toggle="collapse" data-bs-target="#collapseThree"
                    aria-controls="collapseThree">
                    <div class="addproduct-icon list">
                      <h5>
                        <vue-feather type="image" class="add-info"></vue-feather><span>Product Images</span>
                      </h5>
                      <a href="javascript:void(0);"><vue-feather type="chevron-down"
                          class="chevron-down-add"></vue-feather></a>
                    </div>
                  </div>
                </div>
                <div id="collapseThree" class="accordion-collapse collapse show" aria-labelledby="headingThree"
                  data-bs-parent="#accordionExample3">
                  <div class="accordion-body">
                    <div class="text-editor add-list add">
                      <div class="col-lg-12">
                        <div class="add-choosen">
                          <div class="input-blocks">
                            <div class="image-upload">
                              <input type="file" />
                              <div class="image-uploads">
                                <i data-feather="plus-circle" class="plus-down-add me-0"></i>
                                <h4>Add Images</h4>
                              </div>
                            </div>
                          </div>

                          <div class="phone-img" v-if="isVisible">
                            <img src="@/assets/img/products/phone-add-2.png" alt="image" />
                            <a href="javascript:void(0);" @click="hideProduct"><vue-feather type="x"
                                class="x-square-add remove-product"></vue-feather></a>
                          </div>
                          <div class="phone-img" v-if="isVisible1">
                            <img src="@/assets/img/products/phone-add-1.png" alt="image" />
                            <a href="javascript:void(0);" @click="hideProduct1"><vue-feather type="x"
                                class="x-square-add remove-product"></vue-feather></a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> -->

            <div class="accordion-card-one accordion" id="accordionExample2">
              <div class="accordion-item">
                <div class="accordion-header" id="headingTwo">
                  <div
                    class="accordion-button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-controls="collapseTwo"
                  >
                    <div class="text-editor add-list">
                      <div class="addproduct-icon list icon">
                        <h5>
                          <vue-feather
                            type="life-buoy"
                            class="add-info"
                          ></vue-feather
                          ><span>Pricing & Stocks</span>
                        </h5>
                        <a href="javascript:void(0);"
                          ><vue-feather
                            type="chevron-down"
                            class="chevron-down-add"
                          ></vue-feather
                        ></a>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  id="collapseTwo"
                  class="accordion-collapse collapse show"
                  aria-labelledby="headingTwo"
                  data-bs-parent="#accordionExample2"
                >
                  <div class="accordion-body">
                    <!-- <div class="input-blocks add-products">
                      <label class="d-block">Product Type</label>
                      <div class="single-pill-product">
                        <ul class="nav nav-pills" id="pills-tab1" role="tablist">
                          <li class="nav-item" role="presentation">
                            <span class="custom_radio me-4 mb-0 active" id="pills-home-tab" data-bs-toggle="pill"
                              data-bs-target="#pills-home" role="tab" aria-controls="pills-home" aria-selected="true">
                              <input type="radio" class="form-control" name="payment" />
                              <span class="checkmark"></span> Single Product</span>
                          </li>
                          <li class="nav-item" role="presentation">
                            <span class="custom_radio me-2 mb-0" id="pills-profile-tab" data-bs-toggle="pill"
                              data-bs-target="#pills-profile" role="tab" aria-controls="pills-profile"
                              aria-selected="false">
                              <input type="radio" class="form-control" name="sign" />
                              <span class="checkmark"></span> Variable Product</span>
                          </li>
                        </ul>
                      </div>
                    </div> -->
                    <div class="tab-content" id="pills-tabContent">
                      <div
                        class="tab-pane fade show active"
                        id="pills-home"
                        role="tabpanel"
                        aria-labelledby="pills-home-tab"
                      >
                        <div class="row">
                          <div class="col-lg-4 col-sm-6 col-12">
                            <div class="input-blocks add-product">
                              <label>Cost Price</label>
                              <input
                                v-model="productData.cost_price"
                                min="0"
                                step="any"
                                type="number"
                                class="form-control"
                              />
                            </div>
                          </div>
                          <div class="col-lg-4 col-sm-6 col-12">
                            <div class="input-blocks add-product">
                              <label>Selling Price</label>
                              <input
                                v-model="productData.selling_price"
                                min="0"
                                step="any"
                                type="number"
                                class="form-control"
                              />
                            </div>
                          </div>
                          <!-- <div class="col-lg-4 col-sm-6 col-12">
                            <div class="input-blocks add-product">
                              <label>Tax Type</label>
                              <vue-select :options="ExclusiveType" id="exclusivetype" placeholder="Exclusive" />
                            </div>
                          </div> -->
                        </div>

                        <div class="row">
                          <!-- Discount type and value -->
                          <div class="col-lg-4 col-sm-6 col-12">
                            <div class="mb-3 add-product">
                              <label class="form-label">Discount Type</label>
                              <vue-select
                                v-model="productData.discount_type"
                                :options="discountTypeChoices"
                                id="discounttype"
                                placeholder="Choose"
                              />
                            </div>
                          </div>
                          <div class="col-lg-4 col-sm-6 col-12">
                            <div class="input-blocks add-product">
                              <label>Discount Value</label>
                              <input
                                v-model="productData.discount_value"
                                min="0"
                                step="any"
                                type="number"
                                class="form-control"
                              />
                            </div>
                          </div>
                        </div>

                        <!-- Quantity Row -->
                        <div class="row">
                          <!-- <div class="col-lg-4 col-sm-6 col-12">
                            <div class="mb-3 add-product">
                              <div class="add-newplus">
                                <label class="form-label">Unit</label>
                                <a href="javascript:void(0);" data-bs-toggle="modal"
                                  data-bs-target="#add-unit"><vue-feather type="plus-circle"
                                    class="plus-down-add"></vue-feather><span>Add New</span></a>
                              </div>
                              <vue-select v-model="productData.unit" :options="unitChoices" id="chooseaddnew"
                                placeholder="Choose" />
                            </div>
                          </div> -->
                          <div class="col-lg-4 col-sm-6 col-12">
                            <div class="input-blocks add-product">
                              <label>Quantity In Stock</label>
                              <input
                                v-model="productData.quantity_in_stock"
                                type="number"
                                min="0"
                                class="form-control"
                              />
                            </div>
                          </div>
                          <div class="col-lg-4 col-sm-6 col-12">
                            <div class="input-blocks add-product">
                              <label>Minimum Quantity Alert</label>
                              <input
                                v-model="productData.minimum_alert_quantity"
                                type="number"
                                min="0"
                                class="form-control"
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <!-- <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                        <div class="row select-color-add">
                          <div class="col-lg-6 col-sm-6 col-12">
                            <div class="input-blocks add-product">
                              <label>Variant Attribute</label>
                              <div class="row">
                                <div class="col-lg-10 col-sm-10 col-10">
                                  <vue-select :options="VariantChoose" id="varianchoose" placeholder="Choose" />
                                </div>
                                <div class="col-lg-2 col-sm-2 col-2 ps-0">
                                  <div class="add-icon tab">
                                    <a class="btn btn-filter" data-bs-toggle="modal" data-bs-target="#add-units"><i
                                        class="feather feather-plus-circle"></i></a>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="selected-hide-color" id="input-show">
                              <div class="row align-items-center">
                                <div class="col-sm-10">
                                  <div class="input-blocks">
                                    <vue3-tags-input class="input-tags form-control" type="text" data-role="tagsinput"
                                      name="inputBox" id="inputBox" :tags="tags" />
                                  </div>
                                </div>
                                <div class="col-lg-2">
                                  <div class="input-blocks">
                                    <a href="javascript:void(0);" class="remove-color"><i
                                        class="far fa-trash-alt"></i></a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="modal-body-table variant-table" id="variant-table">
                          <div class="table-responsive">
                            <table class="table">
                              <thead>
                                <tr>
                                  <th>Variantion</th>
                                  <th>Variant Value</th>
                                  <th>SKU</th>
                                  <th>Quantity</th>
                                  <th>Price</th>
                                  <th class="no-sort">Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>
                                    <div class="add-product">
                                      <input type="text" class="form-control" value="color" />
                                    </div>
                                  </td>
                                  <td>
                                    <div class="add-product">
                                      <input type="text" class="form-control" value="red" />
                                    </div>
                                  </td>
                                  <td>
                                    <div class="add-product">
                                      <input type="text" class="form-control" value="1234" />
                                    </div>
                                  </td>
                                  <td>
                                    <div class="product-quantity">
                                      <span class="quantity-btn"><i data-feather="minus-circle"
                                          class="feather-search"></i></span>
                                      <input type="text" class="quntity-input" value="2" />
                                      <span class="quantity-btn">+<i data-feather="plus-circle"
                                          class="plus-circle"></i></span>
                                    </div>
                                  </td>
                                  <td>
                                    <div class="add-product">
                                      <input type="text" class="form-control" value="50000" />
                                    </div>
                                  </td>
                                  <td class="action-table-data">
                                    <div class="edit-delete-action">
                                      <div class="input-block add-lists">
                                        <label class="checkboxs">
                                          <input type="checkbox" checked />
                                          <span class="checkmarks"></span>
                                        </label>
                                      </div>
                                      <a class="me-2 p-2" href="javascript:void(0);" data-bs-toggle="modal"
                                        data-bs-target="#add-variation">
                                        <i data-feather="plus" class="feather-edit"></i>
                                      </a>
                                      <a class="confirm-text p-2" @click="showConfirmation" href="javascript:void(0);">
                                        <i data-feather="trash-2" class="feather-trash-2"></i>
                                      </a>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <div class="add-product">
                                      <input type="text" class="form-control" value="color" />
                                    </div>
                                  </td>
                                  <td>
                                    <div class="add-product">
                                      <input type="text" class="form-control" value="black" />
                                    </div>
                                  </td>
                                  <td>
                                    <div class="add-product">
                                      <input type="text" class="form-control" value="2345" />
                                    </div>
                                  </td>
                                  <td>
                                    <div class="product-quantity">
                                      <span class="quantity-btn"><i data-feather="minus-circle"
                                          class="feather-search"></i></span>
                                      <input type="text" class="quntity-input" value="3" />
                                      <span class="quantity-btn">+<i data-feather="plus-circle"
                                          class="plus-circle"></i></span>
                                    </div>
                                  </td>
                                  <td>
                                    <div class="add-product">
                                      <input type="text" class="form-control" value="50000" />
                                    </div>
                                  </td>
                                  <td class="action-table-data">
                                    <div class="edit-delete-action">
                                      <div class="input-block add-lists">
                                        <label class="checkboxs">
                                          <input type="checkbox" checked />
                                          <span class="checkmarks"></span>
                                        </label>
                                      </div>
                                      <a class="me-2 p-2" href="javascript:void(0);" data-bs-toggle="modal"
                                        data-bs-target="#edit-units">
                                        <i data-feather="plus" class="feather-edit"></i>
                                      </a>
                                      <a class="confirm-text p-2" @click="showConfirmation" href="javascript:void(0);">
                                        <i data-feather="trash-2" class="feather-trash-2"></i>
                                      </a>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="accordion-card-one accordion" id="accordionExample4">
              <div class="accordion-item">
                <div class="accordion-header" id="headingFour">
                  <div class="accordion-button" data-bs-toggle="collapse" data-bs-target="#collapseFour"
                    aria-controls="collapseFour">
                    <div class="text-editor add-list">
                      <div class="addproduct-icon list">
                        <h5>
                          <vue-feather type="list" class="add-info"></vue-feather><span>Custom Fields</span>
                        </h5>
                        <a href="javascript:void(0);"><vue-feather type="chevron-down"
                            class="chevron-down-add"></vue-feather></a>
                      </div>
                    </div>
                  </div>
                </div>
                <div id="collapseFour" class="accordion-collapse collapse show" aria-labelledby="headingFour"
                  data-bs-parent="#accordionExample4">
                  <div class="accordion-body">
                    <div class="text-editor add-list add">
                      <div class="custom-filed">
                        <div class="input-block add-lists">
                          <label class="checkboxs">
                            <input type="checkbox" />
                            <span class="checkmarks"></span>Warranties
                          </label>
                          <label class="checkboxs">
                            <input type="checkbox" />
                            <span class="checkmarks"></span>Manufacturer
                          </label>
                          <label class="checkboxs">
                            <input type="checkbox" />
                            <span class="checkmarks"></span>Expiry
                          </label>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-lg-4 col-sm-6 col-12">
                          <div class="input-blocks add-product">
                            <label>Quantity Alert</label>
                            <input type="text" class="form-control" />
                          </div>
                        </div>
                        <div class="col-lg-4 col-sm-6 col-12">
                          <div class="input-blocks">
                            <label>Manufactured Date</label>

                            <div class="input-groupicon calender-input">
                              <vue-feather type="calendar" class="info-img"></vue-feather>
                              <date-picker v-model="startdate" placeholder="Choose Date" class="form-control"
                                :editable="true" :clearable="false" :input-format="dateFormat" />
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-4 col-sm-6 col-12">
                          <div class="input-blocks">
                            <label>Expiry On</label>

                            <div class="input-groupicon calender-input">
                              <vue-feather type="calendar" class="info-img"></vue-feather>
                              <date-picker v-model="startdateOne" placeholder="Choose Date" class="form-control"
                                :editable="true" :clearable="false" :input-format="dateFormat" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> -->
          </div>
        </div>
        <div class="col-lg-12">
          <div class="btn-addproduct mb-4">
            <button type="button" class="btn btn-cancel me-2">Cancel</button>
            <button type="submit" class="btn btn-submit">Save Product</button>
          </div>
        </div>
      </form>
      <!-- /add -->
    </div>
  </div>
  <add-product-modal
    @brand-added="handleBrandAdded"
    @category-added="handleCategoryAdded"
  ></add-product-modal>
</template>
<script>
import { ref } from "vue";
import Vue3TagsInput from "vue3-tags-input";
import Swal from "sweetalert2";
import { selectedStoreState } from "@/state/storeState";
import { fetchStoreBrands } from "@/api/productBrands";
import { fetchStoreCategories } from "@/api/productCategories";
import {
  createStoreProduct,
  fetchStoreProductDetails,
  updateStoreProduct,
} from "@/api/products";
import { convertDataURLToFile, getFileInfoFromURL } from "@/assets/js/utils";

// const currentDate = ref(new Date());
// const currentDateOne = ref(new Date());

export default {
  components: {
    Vue3TagsInput,
  },
  data() {
    return {
      storeId: null,
      isVisible: true,
      isVisible1: true,
      loading: false,
      isEditMode: false,

      // select options
      brandChoices: ["Choose"],
      categoryChoices: ["Choose"],
      discountTypeChoices: ["Choose", "Percentage", "Cash"],

      // select options data from backend
      brandsData: [],
      categoriesData: [],

      // form input bindings for v-model
      productData: {
        product_name: "",
        selling_price: 0.0,
        cost_price: 0.0,
        quantity_in_stock: 0,
        minimum_alert_quantity: 0,
        publiished: true,
        discount_type: "Percentage", // Percentage / Cash
        discount_value: 0,
        description: "",
        store: "", // uuid
        category: "", // uuid
        brand: "", // uuid
        image: "", // uuid
      },
      productImage: {
        imagePreviewURL: "",
        imageFileName: "",
        imageFileType: "",
        imageFile: null,
      },

      // unitChoices: ["Choose",],
      // ExclusiveType: ["Exclusive", "Sales Tax"],
      // VariantChoose: ["Choose", "Color", "Red", "Black"],
      // ChooseCode: ["Choose", "Code34", "Code35", "Code36"],
      // SellingType: ["Choose", "Transactional selling", "Solution selling"],
      // SubSubCategory: ["Choose", "Fruits", "Computers", "Shoes"],
      // SubCategory: ["Choose", "Lenovo", "Electronics"],
      // WarhouseStore: ["Choose", "Legendary", "Determined", "Sincere"],
      // ThomasStore: ["Choose", "Thomas", "Rasmussen", "Fred john"],
      // tags: ["red", "black"],
      // startdate: currentDate,
      // startdateOne: currentDateOne,
      // dateFormat: "dd-MM-yyyy",
    };
  },
  async created() {
    await this.getStoreBrands();
    await this.getStoreCategories();

    if (this.$route.path.endsWith("edit-product")) {
      this.isEditMode = true;
      const productId = this.$route.params.productId;
      this.loading = true;
      this.setProductDetails(productId);
    } else {
      this.isEditMode = false;
    }
  },
  methods: {
    async setProductDetails(productId) {
      fetchStoreProductDetails(selectedStoreState.id, productId)
        .then((data) => {
          // only matching fields get populated
          this.productData = {
            ...this.productData,
            ...data,
          };
          // find brand with corresponding id from data and set brand in productData as brand name
          const brandId = this.productData.brand.id;
          const brandName = this.brandsData.find(
            (brand) => brand.id === brandId
          )?.name;
          this.productData.brand = brandName;

          // same for category
          const categoryId = this.productData.category.id;
          const categoryName = this.categoriesData.find(
            (category) => category.id === categoryId
          )?.category_name;
          this.productData.category = categoryName;

          // set image preview
          this.productImage.imagePreviewURL = this.productData.image;
          // const {fileName, fileType} = getFileInfoFromURL(this.productData.image);
          // this.productImage.imageFileName = fileName;
          // this.productImage.imageFileType = fileType;
        })
        .catch((error) => {
          // sweet alert
          Swal.fire({
            title: "Error Creating Product",
            text: error.response.data.message,
            icon: "error",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 5000,
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async getStoreBrands() {
      fetchStoreBrands(selectedStoreState.id)
        .then((data) => {
          this.brandsData = data;
          this.brandChoices = [
            "Choose",
            ...this.brandsData.map((brand) => brand.name),
          ];
        })
        .catch((error) => {})
        .finally(() => {});
    },
    async getStoreCategories() {
      // Make API Call to get categories
      fetchStoreCategories(selectedStoreState.id)
        .then((data) => {
          this.categoriesData = data;
          this.categoryChoices = [
            "Choose",
            ...this.categoriesData.map((category) => category.category_name),
          ];
        })
        .catch((error) => {})
        .finally(() => {});
    },
    submitForm() {
      console.log("WHEN SUBMITTING");
      console.log(this.productData);

      // prepare data to go to backend
      // get product brand id using product name
      const brandId = this.brandsData.find(
        (brand) => brand.name === this.productData.brand
      )?.id;
      this.productData.brand = brandId;

      // get product category id using product name
      const categoryId = this.categoriesData.find(
        (category) => category.category_name === this.productData.category
      )?.id;
      this.productData.category = categoryId;

      // get product store id using store id
      this.productData.store = selectedStoreState.id;

      // uppercase discount type
      this.productData.discount_type =
        this.productData.discount_type.toUpperCase();

      // validate form inputs

      // calculate discount value if discount type is percentage

      // Initialize FormData object
      const formData = new FormData();

      // Append the form fields to the FormData object
      for (const key in this.productData) {
        formData.append(key, this.productData[key]);
      }

      if (this.productImage.imageFileName) {
        // If the image was changed
        // Convert image preview URL to File object
        const imageFile = convertDataURLToFile(
          this.productImage.imagePreviewURL,
          this.productImage.imageFileName,
          this.productImage.imageFileType
        );
        formData.set("image", imageFile);
      } else {
        alert("HERE");
        formData.delete("image");
        console.log(formData);
      }

      // Make API Call to create product
      this.loading = true;
      if (this.isEditMode) {
        updateStoreProduct(selectedStoreState.id, this.productData.id, formData)
          .then((product) => {
            // Show success alert
            Swal.fire({
              title: "Product Modified Successfully",
              text: product.product_name,
              icon: "success",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
            });

            // route user to product detail or list
            this.$router.push(
              `/inventory/products/${product.id}/product-details`
            );
          })
          .catch((error) => {
            Swal.fire({
              title: "Error Editing Product",
              text: error.response.data.message,
              icon: "error",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 5000,
            });
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        createStoreProduct(selectedStoreState.id, formData)
          .then((product) => {
            // Show success alert
            Swal.fire({
              title: "Product Added Successfully",
              text: product.product_name,
              icon: "success",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
            });

            // route user to product detail or list
            this.$router.push(
              `/inventory/products/${product.id}/product-details`
            );
          })
          .catch((error) => {
            Swal.fire({
              title: "Error Creating Product",
              text: error.response.data.message,
              icon: "error",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 5000,
            });
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    handleBrandAdded(brandName) {
      this.getStoreBrands();
      this.productData.brand = brandName;
    },
    handleCategoryAdded(categoryName) {
      this.getStoreCategories();
      this.productData.category = categoryName;
    },
    removeProductImage() {
      // Show alert asking user to confirm to remove product image.
      Swal.fire({
        title: "Are you sure you want to remove this product's image?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        confirmButtonText: "Yes, remove it!",
        cancelButtonColor: "#3085d6",
        confirmButtonClass: "btn btn-danger ml-1",
        cancelButtonClass: "btn btn-outline-primary",
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.productImage = {
            imagePreviewURL: "",
            imageFileName: "",
            imageFileType: "",
            imageFile: null,
          };
        }
      });
    },
    handleImageInputChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;

      // Define an array of accepted image types
      const acceptedTypes = [
        "image/jpeg",
        "image/png",
        "image/gif",
        "image/webp",
        "image/bmp",
      ];

      // Get the file type
      const fileType = files[0].type;

      // Check if the file type is in the accepted types array
      if (!acceptedTypes.includes(fileType)) {
        Swal.fire({
          title: "Invalid image file type.",
          text: "Supported file types are: JPEG, PNG, GIF, WebP, BMP",
          icon: "error",
        });
        return;
      }

      this.createImage(files[0]);
    },

    createImage(file) {
      var reader = new FileReader();
      reader.onload = (e) => {
        this.productImage.imagePreviewURL = e.target.result;
      };
      this.productImage.imageFileName = file.name;
      this.productImage.imageFileType = file.type;
      this.productImage.imageFile = file; // Store the actual file object
      reader.readAsDataURL(file);
    },
    toggleCollapse() {
      const collapseHeader = this.$refs.collapseHeader;

      if (collapseHeader) {
        collapseHeader.classList.toggle("active");
        document.body.classList.toggle("header-collapse");
      }
    },
  },
};
</script>
