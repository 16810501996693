<template>
  <div>
    <div class="modal fade" id="edit-sales-new">
      <div class="modal-dialog edit-sales-modal">
        <div class="modal-content">
          <div class="page-wrapper p-0 m-0">
            <div class="content p-0">
              <div class="page-header p-4 mb-0">
                <div class="add-item new-sale-items d-flex">
                  <div class="page-title">
                    <h4>Edit Sales</h4>
                  </div>
                  <button
                    type="button"
                    class="close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
              </div>
              <div class="card">
                <div class="card-body">
                  <form @submit.prevent="submitForm">
                    <div class="row">
                      <div class="col-lg-4 col-sm-6 col-12">
                        <div class="input-blocks">
                          <label>Customer</label>
                          <div class="row">
                            <div class="col-lg-10 col-sm-10 col-10">
                              <vue-select
                                :options="Thomas"
                                id="thomas"
                                placeholder="Thomas"
                              />
                            </div>
                            <div class="col-lg-2 col-sm-2 col-2 ps-0">
                              <div class="add-icon">
                                <a href="javascript:void(0);" class="choose-add"
                                  ><vue-feather
                                    type="plus-circle"
                                    class="plus"
                                  ></vue-feather
                                ></a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4 col-sm-6 col-12">
                        <div class="input-blocks">
                          <label>Purchase Date</label>
                          <div class="input-groupicon calender-input">
                            <vue-feather
                              type="calendar"
                              class="info-img"
                            ></vue-feather>
                            <input
                              type="text"
                              class="datetimepicker"
                              placeholder="19 jan 2023"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4 col-sm-6 col-12">
                        <div class="input-blocks">
                          <label>Supplier</label>
                          <vue-select
                            :options="Shoes"
                            id="shoes"
                            placeholder="Dazzle Shoes"
                          />
                        </div>
                      </div>
                      <div class="col-lg-12 col-sm-6 col-12">
                        <div class="input-blocks">
                          <label>Product Name</label>
                          <div class="input-groupicon select-code">
                            <input
                              type="text"
                              placeholder="Please type product code and select"
                            />
                            <div class="addonset">
                              <img
                                src="@/assets/img/icons/scanners.svg"
                                alt="img"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="table-responsive no-pagination">
                      <table class="table datanew">
                        <thead>
                          <tr>
                            <th>Product</th>
                            <th>Qty</th>
                            <th>Purchase Price($)</th>
                            <th>Discount($)</th>
                            <th>Tax(%)</th>
                            <th>Tax Amount($)</th>
                            <th>Unit Cost($)</th>
                            <th>Total Cost(%)</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <div class="productimgname">
                                <a
                                  href="javascript:void(0);"
                                  class="product-img stock-img"
                                >
                                  <img
                                    src="@/assets/img/products/stock-img-02.png"
                                    alt="product"
                                  />
                                </a>
                                <a href="javascript:void(0);">Nike Jordan</a>
                              </div>
                            </td>
                            <td>
                              <div class="product-quantity">
                                <span class="quantity-btn"
                                  >+<vue-feather
                                    type="plus-circle"
                                    class="plus-circle"
                                  ></vue-feather
                                ></span>
                                <input
                                  type="text"
                                  class="quntity-input"
                                  value="2"
                                />
                                <span class="quantity-btn"
                                  ><vue-feather
                                    type="minus-circle"
                                    class="search"
                                  ></vue-feather
                                ></span>
                              </div>
                            </td>
                            <td>2000</td>
                            <td>500</td>
                            <td>0.00</td>
                            <td>0.00</td>
                            <td>0.00</td>
                            <td>1500</td>
                          </tr>
                          <tr>
                            <td>
                              <div class="productimgname">
                                <a
                                  href="javascript:void(0);"
                                  class="product-img stock-img"
                                >
                                  <img
                                    src="@/assets/img/products/stock-img-03.png"
                                    alt="product"
                                  />
                                </a>
                                <a href="javascript:void(0);"
                                  >Apple Series 5 Watch</a
                                >
                              </div>
                            </td>
                            <td>
                              <div class="product-quantity">
                                <span class="quantity-btn"
                                  >+<vue-feather
                                    type="plus-circle"
                                    class="plus-circle"
                                  ></vue-feather
                                ></span>
                                <input
                                  type="text"
                                  class="quntity-input"
                                  value="2"
                                />
                                <span class="quantity-btn"
                                  ><vue-feather
                                    type="minus-circle"
                                    class="search"
                                  ></vue-feather
                                ></span>
                              </div>
                            </td>
                            <td>3000</td>
                            <td>400</td>
                            <td>0.00</td>
                            <td>0.00</td>
                            <td>0.00</td>
                            <td>1700</td>
                          </tr>
                          <tr>
                            <td>
                              <div class="productimgname">
                                <a
                                  href="javascript:void(0);"
                                  class="product-img stock-img"
                                >
                                  <img
                                    src="@/assets/img/products/stock-img-05.png"
                                    alt="product"
                                  />
                                </a>
                                <a href="javascript:void(0);">Lobar Handy</a>
                              </div>
                            </td>
                            <td>
                              <div class="product-quantity">
                                <span class="quantity-btn"
                                  >+<vue-feather
                                    type="plus-circle"
                                    class="plus-circle"
                                  ></vue-feather
                                ></span>
                                <input
                                  type="text"
                                  class="quntity-input"
                                  value="2"
                                />
                                <span class="quantity-btn"
                                  ><vue-feather
                                    type="minus-circle"
                                    class="search"
                                  ></vue-feather
                                ></span>
                              </div>
                            </td>
                            <td>2500</td>
                            <td>500</td>
                            <td>0.00</td>
                            <td>0.00</td>
                            <td>0.00</td>
                            <td>2000</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div class="row">
                      <div class="col-lg-6 ms-auto">
                        <div
                          class="total-order w-100 max-widthauto m-auto mb-4"
                        >
                          <ul>
                            <li>
                              <h4>Order Tax</h4>
                              <h5>GH₵ 0.00</h5>
                            </li>
                            <li>
                              <h4>Discount</h4>
                              <h5>GH₵ 0.00</h5>
                            </li>
                            <li>
                              <h4>Shipping</h4>
                              <h5>GH₵ 0.00</h5>
                            </li>
                            <li>
                              <h4>Grand Total</h4>
                              <h5>GH₵5200.00</h5>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-lg-3 col-sm-6 col-12">
                        <div class="input-blocks">
                          <label>Order Tax</label>
                          <div class="input-groupicon select-code">
                            <input type="text" placeholder="0" />
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-3 col-sm-6 col-12">
                        <div class="input-blocks">
                          <label>Discount</label>
                          <div class="input-groupicon select-code">
                            <input type="text" placeholder="0" />
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-3 col-sm-6 col-12">
                        <div class="input-blocks">
                          <label>Shipping</label>
                          <div class="input-groupicon select-code">
                            <input type="text" placeholder="0" />
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-3 col-sm-6 col-12">
                        <div class="input-blocks mb-5">
                          <label>Status</label>
                          <vue-select
                            :options="Completed"
                            id="progress"
                            placeholder="Choose"
                          />
                        </div>
                      </div>
                      <div class="col-lg-12">
                        <div class="input-blocks">
                          <label>Notes</label>
                          <textarea class="form-control"></textarea>
                        </div>
                      </div>
                      <div class="col-lg-12 text-end">
                        <button
                          type="button"
                          class="btn btn-cancel add-cancel me-3"
                          data-bs-dismiss="modal"
                        >
                          Cancel
                        </button>
                        <button type="submit" class="btn btn-submit add-sale">
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  setup() {
    return {};
  },
};
</script>

<style lang="scss" scoped></style>
