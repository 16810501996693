import { reactive } from 'vue';

const savedState = localStorage.getItem('authState');
const initialState = savedState ? JSON.parse(savedState) : {
  user: {
    email: "",
    role: "",
  },
    userProfile: {
        createdAt: "",
        firstName: "",
        id: "",
        lastName: "",
        otherNames: "",
        updatedAt: ""
    },
  accessToken: "",
  refreshToken: ""
};

export const authState = reactive(initialState);

export function saveAuthState() {
  localStorage.setItem('authState', JSON.stringify(authState));
}

export function clearAuthState() {
  localStorage.removeItem('authState');
  Object.assign(authState, {
    user: {
      email: "",
      role: "",
    },
    userProfile: {
    createdAt: "",
    firstName: "",
    id: "",
    lastName: "",
    otherNames: "",
    updatedAt: ""
    },
    accessToken: "",
    refreshToken: ""
  });
}
