<template>
    <div v-if="loading" class="modal d-flex align-items-center justify-content-center" id="staticBackdrop"
        data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel"
        aria-hidden="true">
        <div class="spinner-grow text-primary" role="status"><span class="sr-only">Loading...</span></div>
    </div>
</template>

<script>
export default {
    props: {
        loading: {
            type: Boolean,
            required: true,
            default: false,
        },
    }
}
</script>

<style scoped>
/* Ensure the modal covers the entire screen */
.modal {
  background-color: rgba(0, 0, 0, 0.5);
}
</style>