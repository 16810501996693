import axiosJSONInstance from "./axiosJSONInstance";

export const fetchStoreCategories = async (storeId) => {
  try {
    const response = await axiosJSONInstance.get(
      `/api/v1/stores/${storeId}/categories/`
    );

    return response.data.data;
  } catch (error) {
    console.error("Error fetching store categories", error);
    throw error;
  }
};

export const createStoreCategory = async (storeId, data) => {
  try {
    const response = await axiosJSONInstance.post(
      `/api/v1/stores/${storeId}/categories/`,
      data
    );

    return response.data.data;
  } catch (error) {
    console.error("Error creating category", error);
    throw error;
  }
};
